<template>
  <div>
    <vue-timeline-update
      v-for="(release, index) in list"
      :key="index"
      :date="new Date(release.published_at)"
      :title="`${release.tag_name} - ${new Date(
        release.published_at
      ).toLocaleDateString()}`"
      :description="getMarkdownHtml(release.body)"
      category="versão"
      icon="code"
      :is-last="release.tag_name == 'v0.0.0.0'"
    />
  </div>
</template>

<script>
const BASE_URL = "https://api.github.com/";
import { marked } from "marked";

export default {
  name: "ListRelease",
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getMarkdownHtml(markdown) {
      return marked.parse(markdown);
    },
    getList() {
      let URL = BASE_URL + "repos/rodrigocananea/evoluti-erp/releases";

      fetch(URL, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "token " + process.env.VUE_APP_GHTOKEN,
        },
        method: "GET",
      })
        .then((response) => response.json().then((x) => (this.list = x)))
        .catch((error) => console.log(error));
    },
  },
};
</script>
