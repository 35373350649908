import Vue from "vue";
import App from "./App.vue";

import VueTimeline from "@growthbunker/vuetimeline";
Vue.use(VueTimeline, {
  // Specify the theme to use: dark or light (dark by default).
  theme: "dark",
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
