<template>
  <div id="app">
    <ListRelease />
  </div>
</template>

<script>
import ListRelease from "./components/ListRelease.vue";

export default {
  name: "App",
  components: {
    ListRelease,
  },
};
</script>

<style>
body {
  color: #f5f5f5;
  height: 100vh;
  background: #0f111a;
  padding: 2vw 5vh 1vw 5vh;
}
</style>
